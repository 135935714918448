import { useContext, createContext, useEffect, useState } from "react";
import { setCookie, getCookie } from "cookies-next";
import pick from "pick-random-weighted";
import { useRouter } from "next/router";

const ExperimentContext = createContext();

export const useExperiment = () => useContext(ExperimentContext);

const allExp = {
  original: 1,
};

const experimentConfig = Object.keys(allExp).map(function (key) {
  return [key, allExp[key]];
});

export const EXPERIMENT_ENUM = "experimentGroup";

export const ExperimentContextProvider = ({ children }) => {
  const router = useRouter();
  const chosenExperiment = router.query.force_experiment_group
    ? router.query.force_experiment_group
    : getCookie(EXPERIMENT_ENUM);
  const defaultExperiment =
    allExp[chosenExperiment] !== undefined && allExp[chosenExperiment] !== null
      ? chosenExperiment
      : "original";
  const [experiment, setExperiment] = useState(defaultExperiment);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!chosenExperiment) {
      const exp = pick(experimentConfig);
      setExperiment(exp);

      // 14 day max age, 60 seconds in a minute, 60 minutes in an hour, 24 hours in a day, 14 days in 2 weeks
      setCookie(EXPERIMENT_ENUM, exp, {
        maxAge: 60 * 60 * 24 * 14,
      });

      window.localStorage.setItem("expid", exp);
    } else if (router.query.force_experiment_group) {
      // 14 day max age, 60 seconds in a minute, 60 minutes in an hour, 24 hours in a day, 14 days in 2 weeks
      setCookie(EXPERIMENT_ENUM, chosenExperiment, {
        maxAge: 60 * 60 * 24 * 14,
      });
      window.localStorage.setItem("expid", chosenExperiment);
    }

    setLoaded(true);
  }, [chosenExperiment, router.query.force_experiment_group, router]);

  return (
    <ExperimentContext.Provider
      value={{
        experiment,
        setExperiment,
      }}
    >
      {loaded ? children : null}
    </ExperimentContext.Provider>
  );
};

export default ExperimentContext;
