import API from "~/config/api";
import fetchJson from "~/utils/Fetch/json";
import storage from "~/utils/Storage";
import authConfig from "~/config/auth";

export const getCRMLink = async () => {
  const auth = storage.getObject("auth");
  let token = await fetchJson(
    API.Auth.CRMToken({}),
    API.Auth.CONFIG(auth)
  ).then(({ data }) => {
    return data.token;
  });

  let url = new URL("profile/v2", authConfig.rexCRMUrl);
  url.searchParams.append("l", token);

  if (
    process.env.CRM_DOMAIN == "https://agentcrm-mobile.staging.rexchange.io"
  ) {
    url.searchParams.append("source", "realestateagentsstaging");
  } else {
    url.searchParams.append("source", "realestateagents");
  }

  return url.href;
};

export const login = async (
  login,
  password,
  rememberMe = true,
  redirect = false,
  seal = false
) => {
  const sessionData = { login, password, rememberMe, seal };
  const resp = await fetchJson(
    "/api/next/agent/login",
    API.POST_CONFIG({ data: { login_session: sessionData } })
  );
  const { user, auth, persistent } = resp.data;
  // clear anything that might exist
  storage.removeItem("auth");
  storage.removeItem("user");

  storage.persistent = persistent;
  storage.setObject("auth", auth);
  storage.setObject("user", user);
  localStorage.removeItem("agentSignup");
  const urlParams = new URLSearchParams(window.location.search);
  const prevLocation = urlParams.get("prev");
  if (prevLocation) {
    window.location.replace(
      window.location.protocol + "//" + decodeURI(prevLocation)
    );
  } else {
    const link = await getCRMLink();
    let newLink = new URL(link);
    if (redirect != false) {
      newLink.pathname = redirect;
    }

    window.location.replace(newLink.href);
  }

  return user;
};

export const validate = async (url) => {
  const resp = await fetchJson(
    "/api/next/agent/validate",
    API.POST_CONFIG({ data: { url } })
  ).catch((error) => {
    storage.removeItem("auth");
    storage.removeItem("user");
    storage.persistent = false;
    return error;
  });

  const { auth, user, isLoggedIn } = resp.data;
  storage.setObject("auth", auth);
  storage.setObject("user", user);

  return user;
};

export const logout = async () => {
  return fetchJson("/api/next/agent/logout", API.GET_CONFIG({})).then(() => {
    storage.removeItem("auth");
    storage.removeItem("user");
    storage.persistent = false;
  });
};

// Only creates a new agent. Don't use for a consumer user
export const create = () => {
  const sessionData = JSON.parse(localStorage.getItem("claim_profile_session"));

  return fetchJson(
    "/api/next/agent/sign-up/create-agent",
    API.POST_CONFIG({ data: { claim_profile_session: sessionData } })
  );
};

export const lastRequest = () => {
  const auth = storage.getObject("auth");
  return fetchJson(API.Auth.lastRequest({}), API.Auth.CONFIG(auth));
};

// Polls to see if a new_agent train has completed.
export const pollTrain = (request_uuid) => {
  return new Promise((resolve, reject) => {
    // const auth = storage.getObject("auth");
    fetchJson(
      API.Auth.checkTrain({}),
      API.POST_CONFIG({ data: { request_uuid } })
    )
      .then(({ data }) => {
        if (data.completed) resolve(data);
        reject(data);
      })
      .catch(({ error, data }) => {
        reject(data);
      });
  });
};

export const checkEmail = (email) => {
  return fetchJson(API.Auth.checkEmail(), API.POST_CONFIG({ data: { email } }));
};

export const checkSlug = () => {
  const sessionData = JSON.parse(localStorage.getItem("claim_profile_session"));

  const payload = {
    slug: `${sessionData.firstName.toLowerCase()}-${sessionData.lastName.toLowerCase()}-${sessionData.state.toLowerCase()}`,
    agent: {
      email: sessionData.email,
      phone_office: sessionData.officeNumber,
      phone_mobile: sessionData.mobileNumber,
    },
  };

  return fetchJson(
    API.Auth.checkSlug(),
    API.POST_CONFIG({ data: { ...payload } })
  );
};
