export class Store {
  static get storage() {
    if (this.persistent) return localStorage;
    return sessionStorage;
  }

  static get persistent() {
    return JSON.parse(localStorage.getItem("persistent")) || false;
  }

  static set persistent(value) {
    value = Boolean(value);
    localStorage.setItem("persistent", JSON.stringify(value));
  }

  get length() {
    return this.storage.length;
  }

  static getItem(key) {
    return this.storage.getItem(key);
  }

  static getObject(key) {
    let value = this.getItem(key);
    return value && JSON.parse(value);
  }

  static setItem(key, value) {
    this.storage.setItem(key, value);
  }

  static setObject(key, value) {
    this.setItem(key, JSON.stringify(value));
  }

  static removeItem(key) {
    this.storage.removeItem(key);
  }

  static clear() {
    this.storage.clear();
  }
}

const storage = Store;

export class Flash {
  static get storage() {
    return sessionStorage;
  }

  static getItem(key) {
    let tempObj = JSON.parse(this.storage.getItem("temp")) || {};
    const value = tempObj[key];
    delete tempObj[key];
    return value;
  }

  static setItem(key, value) {
    let tempObj = JSON.parse(this.storage.getItem("temp")) || {};
    tempObj[key] = value;
    this.storage.setItem("temp", JSON.stringify(tempObj));
  }

  static removeItem(key) {
    let tempObj = JSON.parse(this.storage.getItem("temp")) || {};
    delete tempObj[key];
    this.storage.setItem("temp", JSON.stringify(tempObj));
  }

  static clear() {
    this.storage.removeItem("temp");
  }

  static hasItem(key) {
    let tempObj = JSON.parse(this.storage.getItem("temp")) || {};
    const value = tempObj[key];
    return value !== null && value !== undefined;
  }
}

export const flash = Flash;

export default storage;
