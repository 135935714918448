const auth = {
  allowLogin: true,
  allow3rdParty: false,
  allowUserSignup: false,
  allowAgentSignup: true,
  loginUrl: "/sign-in",
  logoutUrl: "/logout",
  agentClaim: "/agent/sign-up",
  rexLoginUrl: (() => {
    const url = new URL("login", process.env.CRM_DOMAIN);
    return url.href;
  })(),
  rexProfileUrl: (() => {
    const url = new URL("profile", process.env.CRM_DOMAIN);
    return url.href;
  })(),
  rexCRMUrl: process.env.CRM_DOMAIN,
  rexLogoutUrl: (() => {
    let url = new URL("logout", process.env.CRM_DOMAIN);
    if (
      process.env.CRM_DOMAIN == "https://agentcrm-mobile.staging.rexchange.io"
    ) {
      url.searchParams.append("source", "realestateagentsstaging");
    } else {
      url.searchParams.append("source", "realestateagents");
    }
    url.searchParams.append("transfer", "rea");
    return url.href;
  })(),
};

export default auth;
