import { useEffect } from "react";
import Router from "next/router";
import { validate } from "~/utils/Auth";
import API from "~/config/api";
import useSWR from "swr";

export default function useUser({
  redirectTo = "",
  redirectIfFound = true,
} = {}) {
  const {
    data: user,
    error: error,
    isValidating: isValidating,
    mutate: mutateUser,
  } = useSWR(API.Auth.validate({}), validate);

  useEffect(() => {
    if (!redirectTo || !user) return;

    if (
      (redirectTo && redirectIfFound && !user?.isLoggedIn) ||
      (!redirectIfFound && user?.isLoggedIn)
    ) {
      Router.push(redirectTo);
    }
  }, [user, redirectIfFound, redirectTo]);

  return { user, mutateUser, isValidating };
}
