import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLeadUUID } from "~/utils/UUID";
import { logout } from "~/utils/Auth";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import auth from "~/config/auth";
import Dropdown from "~/components/molecules/Dropdown";
import Link from "next/link";
import styles from "~/sass/organisms/Navbar.module.scss";
import useUser from "~/utils/useUser";
import MobileIcon from "~/components/atoms/svgs/MobileIcon";
import CloseIcon from "~/components/atoms/svgs/CloseIcon";
import SearchIcon from "~/components/atoms/svgs/SearchIcon";
import Image from "next/image";
import NavbarLogo from "~/public/static/images/logos/rea-icon-color.2x.min.webp";
import dynamic from "next/dynamic";
import { StyleSheet, css } from "aphrodite";
import { COLORS } from "~/config/themes";
import BlogMenu from "~/components/Blog/BlogMenu";

const LazyNavSearch = dynamic(
  () => import("~/components/organisms/NavSearch"),
  {
    ssr: false,
  }
);

export default function Navbar({
  sticky,
  utilNav,
  floating,
  theme,
  simple,
  twopageFunnel,
  search,
  blogMenu,
  noChange,
  blog_hide_tags,
}) {
  const [logoHref, setLogoHref] = useState("/");
  const { user, mutateUser } = useUser();
  const [stuck, setStuck] = useState(false);
  const [isAgent, setIsAgent] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [session] = useState(() => {
    let sessionData = localStorage.getItem("primeEligibility");
    sessionData = JSON.parse(sessionData);
    return sessionData;
  });
  const navbarRef = useRef();
  const [chosenTheme, setChosenTheme] = useState(theme);

  const router = useRouter();

  useEffect(() => {
    if (window.location.href.includes("top-agents")) {
      setLogoHref("/top-agents");
    }
  }, []);

  useEffect(() => {
    setChosenTheme(theme);
  }, [theme]);

  useEffect(() => {
    if (
      !floating &&
      (router?.query?.utm_source?.toLowerCase().includes("nar-email") ||
        localStorage.getItem("utm_source"))
    ) {
      localStorage.setItem("utm_source", true);
      setChosenTheme("nar");
    }
  }, [router?.query?.utm_source]);

  useEffect(() => {
    const navbarEl = navbarRef.current;
    if (navbarEl != undefined) {
      const links = navbarEl.querySelectorAll("a");
      links.forEach((link) => {
        link.addEventListener("click", mobileMenuClose);
      });
    }

    return () => {
      const navbarEl = navbarRef.current;

      if (navbarEl != undefined) {
        const links = navbarEl.querySelectorAll("a");
        links.forEach((link) => {
          link.removeEventListener("click", mobileMenuClose);
        });
      }
    };
  }, []);

  const mobileMenuClose = useCallback(
    (e) => {
      setMobileMenuOpen(false);
    },
    [navbarRef]
  );

  const buyerDropdown = {
    label: "For Buyers",
    items: [
      {
        label: "Buyers Home",
        href: "/buy",
      },
      {
        label: "Buyers Blog",
        href: "/resource/blog/buying/",
      },
      {
        label: "Buying a Home FAQ",
        href: "/faq/for-buyers",
      },
      {
        label: "Working with a Real Estate Agent",
        href: "/working-with-an-agent",
      },
      {
        label: "How to Find a Real Estate Agent",
        href: "/how-to-find-a-real-estate-agent",
      },
      {
        label: "How to Compare Real Estate Agents",
        href: "/how-to-compare-real-estate-agents",
      },
      {
        label: "How a Real Estate Agent Works for You",
        href: "/how-a-real-estate-agent-works-for-you",
      },
    ],
  };

  const sellerDropdown = {
    label: "For Sellers",
    items: [
      {
        label: "Sellers Home",
        href: "/sell",
      },
      {
        label: "Sellers Blog",
        href: "/resource/blog/selling/",
      },
      {
        label: "Listing Your Home FAQ",
        href: "/faq/for-sellers",
      },
      {
        label: "Working with a Real Estate Agent",
        href: "/working-with-an-agent",
      },
      {
        label: "How to Find a Real Estate Agent",
        href: "/how-to-find-a-real-estate-agent",
      },
      {
        label: "How to Compare Real Estate Agents",
        href: "/how-to-compare-real-estate-agents",
      },
      {
        label: "How a Real Estate Agent Works for You",
        href: "/how-a-real-estate-agent-works-for-you",
      },
    ],
  };

  const agentDropdown = {
    label: "For Agents",
    items: [
      {
        label: "Agents Home",
        href: "/for-agents",
      },
      {
        label: "Upgrade to PRIME",
        href: "/prime",
      },
      {
        label: "Personal Relocation Network",
        href: "/personal-relocation-network",
      },
      {
        label: "Agents Blog",
        href: "/resource/blog/agent/",
      },
      {
        label: "FAQ for Agents",
        href: "/faq/for-agents",
      },
    ],
  };

  const aboutDropdown = {
    label: "About",
    items: [
      {
        label: "About Us",
        href: "/about",
      },
      {
        label: "Contact",
        href: "/contact",
      },
      {
        label: "Blog",
        href: "/resource/blog/",
      },
      {
        label: "Guides",
        href: "/resource/real-estate-guides/",
      },
      {
        label: "Reviews",
        href: "/reviews",
      },
      {
        label: "How It Works",
        href: "/how-it-works",
      },
    ],
  };

  const userMenuItems = () => {
    let dropdown = {
      label: (
        <>
          <span>User Menu</span>
        </>
      ),
      items: [],
    };

    let items = [];

    if (isLoggedIn) {
      items = [
        {
          label: "Edit Profile",
          href: `${auth.rexCRMUrl}/profile/v2`,
        },
        {
          label: "Account Settings",
          href: `${auth.rexCRMUrl}/profile/account_settings`,
        },
        {
          label: "Help Center",
          href: `${auth.rexCRMUrl}/helpcenter`,
        },
        {
          label: "Payments",
          href: `${auth.rexCRMUrl}/payments`,
        },
        {
          label: "Sign Out",
          href: "/",
          onClick: async (e) => {
            e.preventDefault();
            const currentUser = JSON.parse(
              localStorage.getItem("user") || sessionStorage.getItem("user")
            );
            mutateUser(await logout(), false);
            sessionStorage.setItem("show-logged-out-modal", true);
            router.push("/");
          },
        },
      ];
    } else {
      items = [
        {
          label: "Complete Sign Up",
          href: auth.agentClaim,
        },
      ];
    }

    dropdown.items = items;
    return dropdown;
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const scrollHandler = useCallback(
    (e) => {
      if (window.scrollY > 0 && !noChange) {
        setStuck(true);
      } else {
        setStuck(false);
      }
    },
    [stuck]
  );

  useEffect(() => {
    if (user?.isLoggedIn) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [user]);

  const renderLogo = () => {
    if (!mobileMenuOpen && chosenTheme == "white" && !stuck) {
      return (
        <picture>
          <source
            srcSet="/static/images/logos/rea-icon-white.1x.min.webp, /static/images/logos/rea-icon-white.2x.min.webp 2x"
            type="image/webp"
          />
          <source
            srcSet="/static/images/logos/rea-icon-white.1x.min.png, /static/images/logos/rea-icon-white.2x.min.png 2x"
            type="image/png"
          />
          <img
            src="/static/images/logos/rea-icon-white.1x.min.png"
            alt="RealEstateAgents.com logo"
            width="206"
            height="40"
            style={{ width: 206 }}
          />
        </picture>
      );
    } else if (!mobileMenuOpen && chosenTheme == "black") {
      return (
        <picture>
          <source
            srcSet="/static/images/logos/rea-icon-black.1x.min.webp, /static/images/logos/rea-icon-black.2x.min.webp 2x"
            type="image/webp"
          />
          <source
            srcSet="/static/images/logos/rea-icon-black.1x.min.png, /static/images/logos/rea-icon-black.2x.min.png 2x"
            type="image/png"
          />
          <img
            src="/static/images/logos/rea-icon-black.1x.min.png"
            alt="RealEstateAgents.com logo"
            width="206"
            height="40"
          />
        </picture>
      );
    }

    return (
      <Image
        src={NavbarLogo}
        alt="RealEstateAgents.com logo"
        width="206"
        height="40"
        priority
      />
    );
  };

  const userMenu = () => {
    if (twopageFunnel) {
      return null;
    }

    if (isLoggedIn || session?.step) {
      return (
        <li>
          <Dropdown
            menu={userMenuItems()}
            className={`${styles["Dropdown__button"]} ${styles["Navbar__user-dropdown"]}`}
          />
        </li>
      );
    }
    return (
      <li>
        <Link prefetch={false} legacyBehavior href={auth.loginUrl}>
          <a className={styles["Navbar__icon-user"]}>Sign In</a>
        </Link>
      </li>
    );
  };

  return (
    <div
      className={
        `${styles["Navbar"]} ${
          chosenTheme == "white" && !stuck ? styles["--white"] : ""
        } ${floating ? styles["--floating"] : ""} ${
          sticky ? styles["--sticky"] : ""
        } ${sticky && stuck ? styles["--stuck"] : ""} ${
          mobileMenuOpen ? styles["--open"] : ""
        }${searchOpen ? styles["--search"] : ""} ${
          blogMenu ? styles["--blog-menu"] : ""
        } ` + css(twopageFunnel && aphStyles.navbar)
      }
      ref={navbarRef}
    >
      <div
        className={
          styles["Navbar__container"] +
          " " +
          css(twopageFunnel && aphStyles.container)
        }
      >
        {((utilNav && !simple) || twopageFunnel) && (
          <ul
            className={
              styles["Navbar__util"] +
              " " +
              css(twopageFunnel && aphStyles.noMargin)
            }
          >
            <li>
              <a href="tel:+18885862349">
                <FontAwesomeIcon
                  icon={["fad", "circle-phone"]}
                  style={{
                    marginRight: 4,
                    height: "16px",
                    width: "16px",
                    background: "transparent",
                  }}
                />
                (888) 586-2349
              </a>
            </li>
          </ul>
        )}
        <div
          className={`${styles["Navbar__main"]} ${
            chosenTheme == "nar" ? styles["--nar"] : ""
          }`.trim()}
        >
          <div
            className={`${styles["Navbar__brand"]} ${
              chosenTheme == "nar" ? styles["--nar"] : ""
            }`.trim()}
          >
            <Link prefetch={false} legacyBehavior href={logoHref}>
              <a>{renderLogo()}</a>
            </Link>
            {chosenTheme == "nar" && (
              <picture>
                <source
                  srcSet="/static/images/nar/nar-logo.1x.min.ee00564.webp /static/images/nar/nar.2x.min.a85ca06.webp 2x"
                  type="image/webp"
                />
                <source
                  srcSet="/static/images/nar/nar-logo.1x.min.2980b52.png, /static/images/nar/nar.2x.min.74a092a.png 2x"
                  type="image/png"
                />
                <img
                  src="/static/images/nar/nar-logo.1x.min.2980b52.png"
                  alt="National Association of REALTORS logo"
                  width="206"
                  height="40"
                />
              </picture>
            )}
          </div>
          {!searchOpen && (
            <nav className={styles["Navbar__nav"]}>
              {!simple && (
                <ul>
                  <li>
                    <Dropdown
                      menu={buyerDropdown}
                      className={styles["Dropdown__button"]}
                    />
                  </li>
                  <li>
                    <Dropdown
                      menu={sellerDropdown}
                      className={styles["Dropdown__button"]}
                    />
                  </li>
                  <li>
                    <Dropdown
                      menu={agentDropdown}
                      className={styles["Dropdown__button"]}
                    />
                  </li>
                  <li>
                    <Dropdown
                      menu={aboutDropdown}
                      className={styles["Dropdown__button"]}
                    />
                  </li>
                </ul>
              )}
            </nav>
          )}
          {searchOpen && (
            <div className={styles["Navbar__search"]}>
              <LazyNavSearch
                setSearchOpen={setSearchOpen}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            </div>
          )}
          {!twopageFunnel && (
            <div className={styles["Navbar__right"]}>
              <ul className={styles["Navbar__user"]}>
                {!simple && search && (
                  <li className={styles["Navbar__search-button-container"]}>
                    <button
                      className={styles["Navbar__search-button"]}
                      onClick={() => {
                        setSearchOpen(!searchOpen);
                      }}
                      aria-label="Search icon"
                    >
                      {searchOpen ? (
                        <CloseIcon />
                      ) : (
                        <SearchIcon id={"navbar-search-icon"} />
                      )}
                    </button>
                  </li>
                )}
                {userMenu()}
              </ul>
            </div>
          )}
          <div
            className={
              styles["Navbar__mobile"] +
              " " +
              css(twopageFunnel && aphStyles.navbarMobile)
            }
          >
            <button
              className={styles["Navbar__mobile-search-button"]}
              onClick={() => {
                if (!searchOpen) {
                  setMobileMenuOpen(false);
                }
                setSearchOpen(!searchOpen);
              }}
              aria-label="Search icon"
            >
              {searchOpen ? (
                <CloseIcon />
              ) : (
                <SearchIcon id={"navbar-search-icon"} />
              )}
            </button>
            <button
              className={styles["Navbar__mobile-menu-button"]}
              onClick={() => {
                if (!mobileMenuOpen) {
                  setSearchOpen(false);
                }
                setMobileMenuOpen(!mobileMenuOpen);
              }}
              aria-label="Mobile Menu"
            >
              <MobileIcon />
            </button>
          </div>
        </div>
      </div>
      {searchOpen && (
        <div className={styles["Navbar__mobile-search"]}>
          <LazyNavSearch
            setSearchOpen={setSearchOpen}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
      )}
      {blogMenu && <BlogMenu blog_hide_tags={blog_hide_tags} />}
    </div>
  );
}

const aphStyles = StyleSheet.create({
  navbar: {
    // borderBottom: `1px solid ${COLORS.PRIME_BLUE(1)}`,
    marginBottom: 0,
    bottom: "unset",
  },
  navbarMobile: {
    display: "none",
  },
  container: {
    maxWidth: "unset",
    paddingLeft: 64,
    paddingRight: 64,
    display: "flex",
    flexDirection: "row-reverse",
    marginLeft: "unset",
    justifyContent: "space-between",
    alignItems: "center",

    "@media only screen and (max-width: 1023px)": {
      paddingLeft: 16,
      paddingRight: 16,
      flexDirection: "row",
      justifyContent: "center",
      columnGap: 0,
    },
  },
  noMargin: {
    margin: 0,
    zIndex: 3,
  },
});

Navbar.defaultProps = {
  sticky: true,
  utilNav: true,
  floating: false,
  simple: false,
  search: true,
  theme: "color",
  blogMenu: false,
  blog_hide_tags: true,
};
