import Head from "next/head";

export default (props) => {
  const COMPANY_NAME = "RealEstateAgents.com";
  const title =
    props.title || `Find Your Perfect Real Estate Agent | RealEstateAgents.com`;
  const url = props.url || "";
  const description = props.description || "";
  const socialImageUrl =
    props.socialImageUrl ||
    "/static/rea-logo-with-text-black-white-background.webp";
  const canonical = props.canonical || props.url;
  const keywords =
    props.keywords ||
    "sell my house, find a real estate agent, find a listing agent, list my home for sale, sell your house fast, sell my house fast, top real estate agents";
  const type = props.type || "website";
  return (
    <Head>
      <title>{title}</title>
      {canonical ? (
        <link rel="canonical" href={canonical.toLowerCase()} />
      ) : null}
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/static/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/static/favicon/favicon-16x16.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/static/favicon/apple-touch-icon.png"
      />
      <link rel="manifest" href="/static/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/static/favicon/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      <link rel="shortcut icon" href="/static/favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msvalidate.01" content="0FC52291BEF3BEBDDC04A1847BAEEDA8" />
      <meta name="theme-color" content="#ffffff" />
      {description && (
        <meta key="description" name="description" content={description} />
      )}
      <meta name="keywords" content={keywords} />
      {/* Search Indexing */}
      {props.noIndex && <meta name="robots" content="noindex"></meta>}
      {/* Social meta tags */}
      {description && <meta property="og:description" content={description} />}
      {/* Image dimensions: minimum 200x200; recommended 1500x1500 */}
      <meta property="og:image" content={socialImageUrl} />
      <meta property="og:image:alt" content={title} />
      <meta property="og:image:secure_url" content={socialImageUrl} />
      <meta property="og:image:type" content={"image/png"} />
      <meta property="og:image:width" content={"1500"} />
      <meta property="og:image:height" content={"1500"} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content={COMPANY_NAME} />
      <meta property="og:url" content={url} />
      {/* Facebook */}
      <meta property="fb:app_id" content={"id"} />
      {/* Twitter */}
      <meta property="twitter:card" content={"summary_large_image"} />
      {/* <meta property="twitter:site" content={"@realestateagents.com"} /> */}
      {description && (
        <meta property="twitter:description" value={description} />
      )}
      <meta property="twitter:image" content={socialImageUrl} />
      {/* Left */}
      <meta property="twitter:label1" value={title} />
      <meta property="twitter:data1" value={title} />
      {/* Right */}
      <meta property="twitter:label2" value={title} />
      <meta property="twitter:data2" value={title} />
      <meta property="twitter:title" value={title} />
      <meta property="twitter:url" value={url} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {(props.breadcrumbJson || props.schema) && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(props.breadcrumbJson || props.schema),
          }}
        ></script>
      )}
      {props.children}
    </Head>
  );
};
