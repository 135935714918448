import styles from "~/sass/molecules/Dropdown.module.scss";
import Link from "next/link";
import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

export default function Dropdown({ menu, className }) {
  const [open, setOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const dropdownEl = useRef();
  const dropdownButtonEl = useRef();

  useEffect(() => {
    document.addEventListener("click", closeDropdown);

    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  const closeDropdown = useCallback((e) => {
    if (dropdownEl.current == null || dropdownButtonEl.current == null) return;

    if (
      !dropdownEl.current.contains(e.target) &&
      !dropdownButtonEl.current.contains(e.target)
    ) {
      setOpen(false);
    }
    if (
      dropdownEl.current.contains(e.target) &&
      e.target != dropdownEl.current
    ) {
      setOpen(false);
    }
  }, []);

  const LinkOrLink = (item) => {
    if (item?.remote) {
      return (
        <a
          href={item.href}
          onClick={(e) => {
            if (item.onClick != undefined) {
              item.onClick(e);
            }
          }}
        >
          {item.label}
        </a>
      );
    } else {
      if (item.useClickFunction) {
        return <div onClick={item.useClickFunction}>{item.label}</div>;
      }
      return (
        <Link prefetch={false} legacyBehavior href={item.href}>
          <a
            onClick={(e) => {
              if (item.onClick != undefined) {
                item.onClick(e);
              }
            }}
          >
            {item.label}
          </a>
        </Link>
      );
    }
  };

  return (
    <div
      className={`${styles["Dropdown"]} ${open ? styles["--open"] : ""} ${
        closing ? styles["--closing"] : ""
      } ${className}`}
      onAnimationEnd={(e) => {
        if (e.animationName == styles["rotateBack"]) {
          setClosing(false);
        }
      }}
    >
      <button
        ref={dropdownButtonEl}
        typeof="button"
        className={styles["Dropdown__button"]}
        onClick={() => {
          if (open) {
            setClosing(true);
          }
          setOpen(!open);
        }}
      >
        {menu.label}
      </button>
      <ul className={styles["Dropdown__menu"]} ref={dropdownEl}>
        {menu.items.map((item) => (
          <li key={item.label}>{LinkOrLink(item)}</li>
        ))}
      </ul>
    </div>
  );
}

Dropdown.propTypes = {
  menu: PropTypes.object,
  className: PropTypes.string,
};

Dropdown.defaultProps = {
  menu: { label: "dropdown", items: [] },
  className: "",
};
