export default function CloseIcon(props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.82081 7.00364L13.822 1.00242C14.0537 0.778721 14.0601 0.409629 13.8364 0.178007C13.6127 -0.0536146 13.2436 -0.0600372 13.012 0.163659C13.0071 0.168339 13.0023 0.173122 12.9976 0.178007L6.99636 6.17923L0.99513 0.177973C0.763507 -0.0457231 0.394415 -0.0393006 0.170719 0.192321C-0.0475116 0.418272 -0.0475116 0.776466 0.170719 1.00242L6.17195 7.00364L0.170719 13.0049C-0.0569063 13.2325 -0.0569063 13.6016 0.170719 13.8293C0.398378 14.0569 0.76747 14.0569 0.99513 13.8293L6.99636 7.82805L12.9976 13.8293C13.2292 14.053 13.5983 14.0466 13.822 13.8149C14.0402 13.589 14.0402 13.2308 13.822 13.0049L7.82081 7.00364Z"
        fill="currentColor"
      />
    </svg>
  );
}
