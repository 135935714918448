import { useRouter } from "next/router";
import styles from "./Blogmenu.module.scss";
import Link from "next/link";
import useUser from "~/utils/useUser";
import { NON_PRIME_FILTERS } from "~/config/blog";

export default function BlogMenu({ blog_hide_tags }) {
  const { user } = useUser();

  const menu = [
    {
      label: "Latest",
      href: "/resource/blog",
    },
    {
      label: "Agent",
      href: "/resource/blog/agent",
    },
    {
      label: "Buying",
      href: "/resource/blog/buying",
    },
    {
      label: "Home Improvement",
      href: "/resource/blog/home-improvement",
    },
    {
      label: "Mortgage",
      href: "/resource/blog/mortgage",
    },
    {
      label: "PRIME",
      href: "/resource/blog/prime",
    },
    {
      label: "Process",
      href: "/resource/blog/process",
    },
    {
      label: "Selling",
      href: "/resource/blog/selling",
    },
  ];

  const router = useRouter();
  return (
    <div className={styles["BlogMenu"]}>
      <nav className={styles["BlogMenu__container"]}>
        <ul className={styles["BlogMenu__list"]}>
          {menu.map((item, key) => (
            <>
              {NON_PRIME_FILTERS.exclude.tags.includes(
                item.label.toLocaleLowerCase()
              ) && blog_hide_tags ? null : (
                <li key={key} className={styles["BlogMenu__item"]}>
                  <Link
                    href={item.href}
                    className={[
                      styles["BlogMenu__item-link"],
                      (router.asPath.includes(item.href) &&
                        item.href !== "/resource/blog") ||
                      (item.href === "/resource/blog" &&
                        (router.asPath === item.href ||
                          router.asPath.includes("/resource/blog/page")))
                        ? styles["--active"]
                        : null,
                    ].join(" ")}
                  >
                    {item.label}
                  </Link>
                </li>
              )}
            </>
          ))}
        </ul>
      </nav>
    </div>
  );
}
